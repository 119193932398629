<template>
  <div>
    <h3 class="mt-4">MÓDULO DE EVALUACIONES</h3>
    <div class="filter-container-class">
      <div class="pb-2">
        <b-form-select
          v-model="selected_evaluations_scoped"
          value-field="value"
          text-field="name"
          class="mt-4 mb-1"
        >
          <option :value="null" disabled>
            -- Seleccione el Filtro a Utilizar --
          </option>
          <option
            v-for="evaluation_view in evaluations_view"
            :key="evaluation_view.value"
            :value="evaluation_view.value"
          >
            {{ evaluation_view.name }}
          </option>
        </b-form-select>
      </div>
      <!-- Filtro -->
      <div class="search noprint" v-if="selected_evaluations_scoped">
        <div class="tag-container mt-2">
          <div
            class="style-tag"
            v-for="(tag, index) in multiple_search"
            :key="index"
          >
            <div class="d-flex">
              <div class="align-self-center">
                {{ tag.name }}
              </div>
              <b-button
                class="p-0"
                variant="none"
                v-b-tooltip.v-secondary.noninteractive="`Eliminar Filtro`"
                @click="deleteTag(index)"
              >
                <b-icon font-scale="1" style="color: white" icon="x"></b-icon>
              </b-button>
            </div>
          </div>
        </div>
        <div class="d-flex mt-1">
          <b-form-select
            class="w-25 mr-3"
            v-model="selected_filter_id"
            :options="filterOptions"
            value-field="value"
            text-field="name"
            placeholder="-- Seleccione el Filtro a Utilizar --"
          >
            <template #first>
              <b-form-select-option :value="null"
                >-- Seleccione una Opción --</b-form-select-option
              >
            </template>
          </b-form-select>
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="search-form"
              type="search"
              v-model="input_search"
              @keydown.enter="addSearchTag"
              placeholder="Buscar..."
            >
            </b-form-input>
            <b-input-group-append>
              <b-button
                class="mr-2"
                :disabled="!input_search"
                @click="addSearchTag"
                >Buscar</b-button
              >
            </b-input-group-append>
            <b-input-group-append>
              <b-button
                :disabled="multiple_search.length == 0"
                @click="multiple_search = []"
                >Limpiar</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <!-- <b-button
        v-can="'evaluations2.add_evaluation'"
        v-if="allows_crud && selected_evaluations_scoped"
        size="sm"
        variant="primary"
        @click="$bvModal.show('modal-created-evaluation')"
        class="mb-3 mt-4"
        >Agregar
        {{
          $getVisibleNames("evaluations2.evaluation", false, "Evaluación")
        }}</b-button
      > -->
    </div>
    <template v-if="selected_evaluations_scoped">
      <ManageEvaluationTable
        :key="key_new_generic_table"
        :pre_filter="pre_filter"
        :evaluation_scoped="selected_evaluations_scoped"
        :Sections="sections"
        :Matters="matters"
        :EgressProfileMatters="egress_profile_matters"
        :allows_crud="allows_crud"
      ></ManageEvaluationTable>
    </template>
    <!-- <b-modal
      :title="
        'Crear ' +
        $getVisibleNames('evaluations2.evaluation', false, 'Evaluación')
      "
      hide-footer
      size="xl"
      id="modal-created-evaluation"
    >
      <NewEvaluationForm
        :evaluation_scoped_id="selected_evaluations_scoped"
        :Sections="sections"
        :Matters="matters"
        :EgressProfileMatters="egress_profile_matters"
        :show_step="selected_evaluations_scoped == 1"
        @closeModal="closeModal()"
      ></NewEvaluationForm>
    </b-modal> -->
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "ManageEvaluations",
  components: {
    ManageEvaluationTable: () => import("./ManageEvaluationTable"),
    // NewEvaluationForm: () =>
    //   import(
    //     "@/components/new-evaluations/ManageEvaluations/NewEvaluationForm"
    //   ),
  },
  props: {
    allows_crud: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      key_new_generic_table: 0,
      selected_evaluations_scoped: null,
      input_search: "",
      multiple_search: [],
      selected_filter_id: null,
      sections: [],
      matters: [],
      egress_profile_matters: [],
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      egress_profiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      faculties: names.FACULTIES,
      campuses: names.CAMPUSES,
      evaluation_types: names.NEW_EVALUATION_TYPES,
      evaluation_categories: names.NEW_EVALUATION_CATEGORIES,
    }),
    filterOptions() {
      let list = [
        { value: 10, name: "Nombre y Descripción" },
        { value: 9, name: "Tipo" },
      ];
      if (this.institution && this.institution.internal_use_id != "duoc_uc")
        list.push({ value: 8, name: "Categoría" });
      if (this.selected_evaluations_scoped == 1)
        list.push({
          value: 1,
          name: this.$getVisibleNames("teaching.section", false, "Sección"),
        });
      if (this.selected_evaluations_scoped == 2)
        list.push({
          value: 2,
          name: this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          ),
        });
      if (this.selected_evaluations_scoped == 3)
        list.push({
          value: 3,
          name: this.$getVisibleNames(
            "teaching.matter",
            false,
            "Asignatura Base"
          ),
        });
      if (this.selected_evaluations_scoped == 4)
        list.push({
          value: 4,
          name: this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil De Egreso"
          ),
        });
      if (this.selected_evaluations_scoped == 5)
        list.push({
          value: 5,
          name: this.$getVisibleNames("mesh.career", false, "Programa"),
        });
      if (this.selected_evaluations_scoped == 6)
        list.push({
          value: 6,
          name: this.$getVisibleNames("mesh.faculty", false, "Facultad"),
        });
      if (this.selected_evaluations_scoped == 7)
        list.push({
          value: 7,
          name: this.$getVisibleNames("mesh.campus", false, "Sede"),
        });
      return list;
    },
    pre_filter() {
      let partial_url =
        "evaluation_scope_default__scope=" + this.selected_evaluations_scoped;
      if (this.scoped_ids != null)
        partial_url =
          partial_url +
          "&evaluation_scope_default__scope_id__in=" +
          this.scoped_ids;
      if (this.category_ids != null)
        partial_url =
          partial_url +
          "&evaluation_category_default__category__in=" +
          this.category_ids;
      if (this.type_ids != null)
        partial_url =
          partial_url + "&evaluation_type_default__type__in=" + this.type_ids;
      if (this.search_names != null)
        partial_url = partial_url + "&search=" + this.search_names;
      return partial_url;
    },
    search_names() {
      const multiple_search_names = this.multiple_search
        .filter((x) => x.value == 10)
        .map((x) => x.input_search);
      if (multiple_search_names.length > 0)
        return multiple_search_names.join(",");
      else return null;
    },
    scoped_ids() {
      if (this.selected_evaluations_scoped == 1) {
        const multiple_search_names = this.multiple_search
          .filter((x) => x.value == this.selected_evaluations_scoped)
          .map((x) => x.input_search);
        const section_ids = this.sections
          .filter(
            (a) =>
              multiple_search_names.filter((b) => this.$filtered(a.name, b))
                .length > 0
          )
          .map((x) => x.id);
        if (section_ids.length > 0) return section_ids.join(",");
        else return null;
      } else if (this.selected_evaluations_scoped == 2) {
        const multiple_search_names = this.multiple_search
          .filter((x) => x.value == this.selected_evaluations_scoped)
          .map((x) => x.input_search);
        const egress_profile_matter_ids = this.egress_profile_matters
          .filter(
            (a) =>
              multiple_search_names.filter((b) =>
                this.$filtered(a.matter_name, b)
              ).length > 0
          )
          .map((x) => x.id);
        if (egress_profile_matter_ids.length > 0)
          return egress_profile_matter_ids.join(",");
        else return null;
      } else if (this.selected_evaluations_scoped == 3) {
        const multiple_search_names = this.multiple_search
          .filter((x) => x.value == this.selected_evaluations_scoped)
          .map((x) => x.input_search);
        const matter_ids = this.matters
          .filter(
            (a) =>
              multiple_search_names.filter((b) => this.$filtered(a.name, b))
                .length > 0
          )
          .map((x) => x.id);
        if (matter_ids.length > 0) return matter_ids.join(",");
        else return null;
      } else if (this.selected_evaluations_scoped == 4) {
        const multiple_search_names = this.multiple_search
          .filter((x) => x.value == this.selected_evaluations_scoped)
          .map((x) => x.input_search);
        const egress_profile_ids = this.egress_profiles
          .filter(
            (a) =>
              multiple_search_names.filter((b) => this.$filtered(a.name, b))
                .length > 0
          )
          .map((x) => x.id);
        if (egress_profile_ids.length > 0) return egress_profile_ids.join(",");
        else return null;
      } else if (this.selected_evaluations_scoped == 5) {
        const multiple_search_names = this.multiple_search
          .filter((x) => x.value == this.selected_evaluations_scoped)
          .map((x) => x.input_search);
        const career_ids = this.careers
          .filter(
            (a) =>
              multiple_search_names.filter((b) => this.$filtered(a.name, b))
                .length > 0
          )
          .map((x) => x.id);
        if (career_ids.length > 0) return career_ids.join(",");
        else return null;
      } else if (this.selected_evaluations_scoped == 6) {
        const multiple_search_names = this.multiple_search
          .filter((x) => x.value == this.selected_evaluations_scoped)
          .map((x) => x.input_search);
        const faculty_ids = this.faculties
          .filter(
            (a) =>
              multiple_search_names.filter((b) => this.$filtered(a.name, b))
                .length > 0
          )
          .map((x) => x.id);
        if (faculty_ids.length > 0) return faculty_ids.join(",");
        else return null;
      } else if (this.selected_evaluations_scoped == 7) {
        const multiple_search_names = this.multiple_search
          .filter((x) => x.value == this.selected_evaluations_scoped)
          .map((x) => x.input_search);
        const campus_ids = this.campuses
          .filter(
            (a) =>
              multiple_search_names.filter((b) => this.$filtered(a.name, b))
                .length > 0
          )
          .map((x) => x.id);
        if (campus_ids.length > 0) return campus_ids.join(",");
        else return null;
      }
      return null;
    },
    category_ids() {
      const multiple_search_names = this.multiple_search
        .filter((x) => x.value == 8)
        .map((x) => x.input_search);
      const categories_ids = this.evaluation_categories
        .filter(
          (a) =>
            multiple_search_names.filter((b) => this.$filtered(a.value, b))
              .length > 0
        )
        .map((x) => x.id);
      if (categories_ids.length > 0) return categories_ids.join(",");
      else return null;
    },
    type_ids() {
      const multiple_search_names = this.multiple_search
        .filter((x) => x.value == 9)
        .map((x) => x.input_search);
      const type_ids = this.evaluation_types
        .filter(
          (a) =>
            multiple_search_names.filter((b) => this.$filtered(a.value, b))
              .length > 0
        )
        .map((x) => x.id);
      if (type_ids.length > 0) return type_ids.join(",");
      else return null;
    },
    evaluations_view() {
      let list = [
        {
          value: 1,
          name:
            "Mostrar " +
            this.$getVisibleNames(
              "evaluations2.evaluation",
              true,
              "Evaluaciones"
            ) +
            " de " +
            this.$getVisibleNames("teaching.section", false, "Sección"),
        },
        {
          value: 3,
          name:
            "Mostrar " +
            this.$getVisibleNames(
              "evaluations2.evaluation",
              true,
              "Evaluaciones"
            ) +
            " de " +
            this.$getVisibleNames("teaching.matter", false, "Asignatura Base"),
        },
      ];
      if (this.institution && this.institution.internal_use_id != "duoc_uc")
        list = list.concat([
          {
            value: 2,
            name:
              "Mostrar " +
              this.$getVisibleNames(
                "evaluations2.evaluation",
                true,
                "Evaluaciones"
              ) +
              " de " +
              this.$getVisibleNames(
                "mesh.egressprofilematter",
                false,
                "Asignatura"
              ),
          },
          {
            value: 4,
            name:
              "Mostrar " +
              this.$getVisibleNames(
                "evaluations2.evaluation",
                true,
                "Evaluaciones"
              ) +
              " de " +
              this.$getVisibleNames(
                "mesh.egressprofile",
                false,
                "Perfil De Egreso"
              ),
          },
          {
            value: 5,
            name:
              "Mostrar " +
              this.$getVisibleNames(
                "evaluations2.evaluation",
                true,
                "Evaluaciones"
              ) +
              " de " +
              this.$getVisibleNames("mesh.career", false, "Programa"),
          },
          {
            value: 6,
            name:
              "Mostrar " +
              this.$getVisibleNames(
                "evaluations2.evaluation",
                true,
                "Evaluaciones"
              ) +
              " de " +
              this.$getVisibleNames("mesh.faculty", false, "Facultad"),
          },
          {
            value: 7,
            name:
              "Mostrar " +
              this.$getVisibleNames(
                "evaluations2.evaluation",
                true,
                "Evaluaciones"
              ) +
              " de " +
              this.$getVisibleNames("mesh.campus", false, "Sede"),
          },
          {
            value: 8,
            name: "Institucional",
          },
        ]);
      return list;
    },
  },
  methods: {
    // closeModal() {
    //   this.$bvModal.hide("modal-created-evaluation");
    //   this.key_new_generic_table++;
    // },
    fetchTrimmedSecMatEPM() {
      this.$restful
        .Get(`/teaching/get_trimmed_sections_matters_and_epms/`)
        .then((response) => {
          this.sections = response.sections;
          this.matters = response.matters;
          this.egress_profile_matters = response.egress_profile_matters;
        });
    },
    changeEvaluationView() {
      this.key_new_generic_table++;
    },
    addSearchTag() {
      if (this.input_search != "" && this.selected_filter_id != null) {
        const selected_filter = this.filterOptions.find(
          (x) => x.value == this.selected_filter_id
        );
        if (selected_filter)
          this.multiple_search.push({
            name: selected_filter.name + ": " + this.input_search,
            input_search: this.input_search,
            value: selected_filter.value,
          });
        this.input_search = "";
        this.changeEvaluationView();
      }
    },
    deleteTag(index) {
      this.multiple_search.splice(index, 1);
      this.changeEvaluationView();
    },
  },
  watch: {
    selected_evaluations_scoped() {
      this.input_search = "";
      this.multiple_search = [];
      this.selected_filter_id = null;
      this.changeEvaluationView();
    },
    pre_filter() {
      this.changeEvaluationView();
    },
    // selected_campus_id() {
    //   if (this.selected_evaluations_scoped == 7) this.changeEvaluationView();
    // },
    // selected_faculty_id() {
    //   if (this.selected_evaluations_scoped == 6) this.changeEvaluationView();
    // },
  },
  created() {
    this.fetchTrimmedSecMatEPM();
    this.$store.dispatch(names.FETCH_NEW_EVALUATION_CATEGORIES);
    this.$store.dispatch(names.FETCH_NEW_EVALUATION_TYPES);
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
  },
};
</script>

<style scoped>
.tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
.style-tag {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  /* text-align: center; */
}
.filter-container-class {
  padding: 1rem !important;
  width: 100%;
  background: #eeeeee;
  border-radius: 10px;
}
.filter-container {
  flex-direction: column;
  display: flex;
  flex-wrap: nowrap;
}
.filter-tittle {
  text-align: left;
}
</style>